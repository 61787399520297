import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export enum Actions {
  OK = 'Ok',
  CANCEL = 'Cancel',
}

@Component({
  selector: 'app-application-submitted-dialog',
  template: `
    <div class="dialog">
      <div class="content">
        <div mat-dialog-title>
          <button mat-button class="close-button" (click)="click(Actions.CANCEL)">&times;</button>
        </div>
        <div class="dialog-body" mat-dialog-content>
          <div class="mb-20">
            {{data.message}}
          </div>
        </div>

        <div mat-dialog-actions class="text-center buttons">
          <button
            *ngFor="let button of data.buttons"
            class="btn d-inline-block btn-md btn-info mr-3"
            [ngClass]="{ 'primary': button.style === 'primary', 'secondary': button.style !== 'primary' }"
            (click)="click(button.type)"
          >{{button.label}}</button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['message-dialog.component.scss']
})
export class MessageDialogComponent {

  Actions = Actions;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      buttons: {
        label: string,
        type: Actions
        style: 'primary' | 'secondary'
      }[]
    },
  ) {}

  click(type: Actions): void {
    this.dialogRef.close(type);
  }
}
